import Button from '../components/Button'
import Card from '../components/Card'
import Layout from '../components/layout/Layout'
import React from 'react'
import { data } from '../data/sys-data'

const tabTile = 'SYSCA | SYS Christian Academy | DayCare, Preescolar y Primaria'

const redes = {
  facebook: 'https://www.facebook.com/SYSCASchool',
  insta: `https://www.instagram.com/SYSCASchool`
}

const URL = 'https://sys.ed.cr/'

const servicios = [
  { title: 'STEAM', description: 'Aplicamos principios de la educación STEAM en todas nuestras aulas.', className: 'bg-gradient-1' },
  { title: 'Talleres', description: 'Nuestro DayCare de 5:30 a 17:30 cuenta con talleres educativos', className: 'bg-gradient-2' },
  {
    title: 'Educación Bilingüe',
    description: 'Desde la primera clase se imparte Inglés como segunda lengua.',
    className:'bg-gradient-3'
  },
]

const Servicio = ({ title, description, className }) => (
  <div className={`flex-1 px-3 `}>
    <Card className={`mb-8 ${className} text-white`}>
      <p className='font-semibold text-xl'>{title}</p>
      <p className='mt-4 text-2xl'>{description}</p>
    </Card>
  </div>
)

const GoToFacebook = () => (
  <p className='pt-6 mt-8 md:mt-1'>
    <a href={redes.facebook}>
      <Button className='cursor-pointer font-bold shadow-lg btn-gradiant' size='lg' href={redes.facebook}>
        Visítamos en Facebook
      </Button>
    </a>
  </p>
)

const GoToInstagram = () => (
  <p className='pt-6 mt-8 md:mt-1'>
    <a href={redes.insta}>
      <Button className='cursor-pointer font-bold shadow-lg btn-gradiant' size='lg' href={redes.insta}>
        Visítamos en Instagram
      </Button>
    </a>
  </p>
)

const LogoImage = () => (
  <div className='lg:w-1/2 lg:pt-0 pt-4 flex justify-center '>
    <div className='bg-gradient-to-tr from-yellow-400 to-red-700 p-2 rounded-full h-fit'>
      <img className='rounded-full shadow-sm max-h-96' src={'/img/logo.png'} alt={tabTile} />
    </div>
  </div>
)

const WorkingFuture = () => (
  <div className="flex flex-col max-h-[62rem] justify-center items-center">
    <img className='rounded-md shadow-sm overflow-hidden' src={'/img/realiza-tus-metas.jpg'} alt="Realiza tus sueños junto a SYS Christian Academy | SYSCA" />
    <div className='font-bold text-4xl text-gradient text-gd-1 pt-3'>
      <p>Trabajamos para un una generación que marcará historia.</p>
    </div>
  </div>
)

const Index = () => (
  <Layout>
    <section className='pt-20 md:pt-40'>
      <div className='container mx-auto px-8 lg:flex'>
        <div className='pb-2 text-center lg:text-left lg:w-1/2'>
          <h1 className='text-4xl lg:text-6xl xl:text-7xl font-bold leading-none title-gradient text-gradient lg:table-caption lg:text-start'>DayCare Preescolar Primaria</h1>
          <h4 className='pt-4 text-gray-600'>Barrio Escalante, San José, Costa Rica</h4>
          <p className='text-xl lg:text-2xl mt-6 font-light'>¡Matrícula abierta!</p>
          <GoToFacebook />
          <GoToInstagram />
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <LogoImage />
      </div>
    </section>
    <section id='servicios' className='py-20 lg:pb-10 lg:pt-20'>
      <div className='container mx-auto text-center'>
        <h2 className='text-3xl lg:text-5xl font-semibold'>Servicios</h2>
        <div className='flex flex-col sm:flex-row sm:-mx-3 mt-12'>
          {servicios.map(item => (
            <Servicio {...item} />
          ))}
        </div>
      </div>
    </section>

    <section  className='py-20 lg:pb-20 lg:pt-20 px-4'>
      <WorkingFuture></WorkingFuture>
    </section>

    <section id='mision' className='py-20 lg:pb-20 lg:pt-20'>
      <div className='container mx-auto text-center'>
        <h2 className='text-3xl lg:text-5xl font-semibold mb-12'>Nuestra Misión</h2>
        <div className='flex justify-center'>
          <div className='max-w-2xl'>
            <Servicio
              tile=''
              className="text-gray-800"
              description='SYS Christian Academy, SYSCA, tiene como misión guiar en su formación integral a los niños según sus diferentes edades, habilitándolos para el desarrollo de sus competencias poniéndolas en práctica dentro y fuera de la escuela, logrando autonomía y responsabilidad tanto en su trabajo escolar como en su persona.'
            />
          </div>
        </div>
      </div>
    </section>

    <section id='contacto' className='py-20 lg:pb-10 lg:pt-20'>
      <div className='container mx-auto text-center'>
        <h2 className='text-3xl lg:text-5xl font-semibold'>Contacto</h2>
        <div className='flex flex-col sm:flex-row sm:-mx-3 mt-12'>
          <div className='flex-1 px-3'>
            <Card className='mb-8'>
              <p className='font-semibold text-xl'>Teléfono</p>
              <p className='mt-4'>
                <a href='tel:+506-4030-5139'>4030-5139</a> {' / '}
                <a href='tel:+506-8560-8591'>8560-8591</a>
              </p>
            </Card>
          </div>
          <div className='flex-1 px-3'>
            <Card className='mb-8'>
              <p className='font-semibold text-xl'>Correo</p>
              <p className='mt-4'>
                <a href='mailto:info@sys.ed.cr'>info@sys.ed.cr</a>
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Index


export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content={data.desc}></meta>

    <meta name="og:title" property="og:title" content={`Escuela ${data.title}`}></meta>
    <meta property="og:description" content={data.desc}/>
    <meta property="og:image" content={`${URL}/img/realiza-tus-metas.jpg`} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_CR" />

    <title>{data.title}</title>
    <link rel="canonical" href={URL} />
  </>
)
